<template>
    <v-app v-if="!loading">
        <router-view/>
    </v-app>
</template>

<script>
import { getUserRoleAPI } from '@/apis/service'

export default {
    data() {
        const profileData = this.$cookies.get("LINE_TENKO_PROFILE_DATA")
        const liffData = this.$cookies.get("LINE_TENKO_LIFF_DATA")
        return {
            profileData,
            liffData,
            loading: true,
        }
    },
    async mounted() {
        let path = $cookies.get("LINE_LIFF_ORIGIN_PATH")
        if(path !== undefined && path !== null && path !== "undefined" && path !== "null") {
            this.$router.push(path)
            $cookies.remove("LINE_LIFF_ORIGIN_PATH")
        }
        // if(!this.profileData || Object.keys(this.profileData).length === 0 ) {
        //     this.getProfile()
        // }
        // else {
        //     this.loading = false
        // }
        this.getProfile()
    },
    methods: {
        async getProfile() {
            await getUserRoleAPI({
                mid: this.liffData.userId,
                cbSuccess: async res => {
                    if(res.data.status === 200 && res.data.message === "Succesfully") {
                        if(res.data.role === 'In-Active') {
                            this.$router.push('/404')
                            this.$cookies.remove('LINE_TENKO_PROFILE_DATA')
                            this.loading = false
                        }
                        else {
                            this.$cookies.set('LINE_TENKO_PROFILE_DATA', JSON.stringify(res.data), "60min")
                            this.loading = false
                        }
                    }
                    else {
                        this.loading = false
                        this.$router.push('/404')
                    }
                },
                cbError: () => {
                    this.loading = false
                }
            })
        },
    },
}
</script>
