import Vue from 'vue'
import VueCookies from 'vue-cookies'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

import App from './App.vue'
import router from './router'
import store from './store'
import ComponentRegistration from "./utility/components-registration"
import vuetify from '@/plugins/vuetify'

import "@/styles/index.css"

const optionsToast = {
  timeout: 2000,
  closeOnClick: true,
  pauseOnHover: true,
}

Vue.config.productionTip = false
ComponentRegistration(Vue)
Vue.use(VueCookies)
Vue.use(Toast, optionsToast)

let vh = window.innerHeight * 0.01
document.documentElement.style.setProperty('--vh', `${vh}px`)

async function runner() {
    new Vue({
        router,
        store,
        vuetify,
        render: h => h(App),
    }).$mount('#app')
}

console.log(process.env.VUE_APP_ENV)
console.log(process.env.VUE_APP_VERSION)
console.log('0.1')

lineLogin(process.env.VUE_APP_LINE_LIFF_ID)

function lineLogin(liff_id) {
    if(window.location.hostname === "localhost") {
        const liffData = {
            userId: "U34bb506b21645009ac1d751139b48a56x",
            email: 'aewtippawanm@gmail.com',
            displayName: 'เรียกหนูว่าอิ๋ว🐹',
            pictureUrl: 'https://profile.line-scdn.net/0hMuLc4MS6ElpxGjiRORJtDU1fHDcGNBQSCXsKbwQeRGNfK1JeSnRebAFIHGtVfwIPSikNP1JNTGNa',
            line_liff_token: 'eyJraWQiOiI5NWU5MTE5ZjY1M2VhZTA5NWJiM2Q4NzFkNmJhOGZmNDY0NjdhYTMxNDU3NWE0NTQzNjE1ZjA1MWQ0YjczNWE2IiwidHlwIjoiSldUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2FjY2Vzcy5saW5lLm1lIiwic3ViIjoiVTVlOGI5YzJkN2Q1YzhmNzRmOTRiYWU1NTA1YjhmY2QzIiwiYXVkIjoiMTY1NzQyODMzOSIsImV4cCI6MTY2NjgwMzgxMCwiaWF0IjoxNjY2ODAwMjEwLCJhbXIiOlsibGluZXNzbyJdLCJuYW1lIjoi4LmA4Lij4Li14Lii4LiB4Lir4LiZ4Li54Lin4LmI4Liy4Lit4Li04LmL4Lin8J-QuSIsInBpY3R1cmUiOiJodHRwczovL3Byb2ZpbGUubGluZS1zY2RuLm5ldC8waE11TGNFNFBNRWxweEdnWEcyMEp0RFUxZkhEY0dOQlFTQ1hzS2J3UWVSR05mSzFKZVNuUmViQUZJSEd0VmZ3SVBTaWtOUDFKTlRHTmEifQ.7OTmLdh9OrBhMul7ImGVfekG_Li6GkQ53DAJBIrd-H9e-n4Laz8VnWW3JZLtdbhIwZoCi-mabKioakDgANIBuQ'
        }
        $cookies.set('LINE_TENKO_LIFF_DATA', JSON.stringify(liffData))
        runner();
    }
    else {
        const path = location.href.split(location.origin)
        const path2 = path[1].split("/?liff.state=")
        if(decodeURIComponent(path2[1]) !== undefined && decodeURIComponent(path2[1]) !== null && decodeURIComponent(path2[1]) !== "undefined" && decodeURIComponent(path2[1]) !== "null") {
            $cookies.set('LINE_LIFF_ORIGIN_PATH', decodeURIComponent(path2[1]))
        }

        liff.init({ liffId: liff_id })
            .then(async () => {
                if (liff.isLoggedIn()) {
                    const profile = await liff.getProfile()
                    profile.line_liff_token = liff.getIDToken()
                    $cookies.set('LINE_TENKO_LIFF_DATA', JSON.stringify(profile), "30min")
                    runner();
                }
                else {
                    liff.login()
                }
            })
            .catch(err => {
                console.log(err)
            })

    }
}