import { render, staticRenderFns } from "./BaseSelectOption.vue?vue&type=template&id=1f498843&scoped=true&"
import script from "./BaseSelectOption.vue?vue&type=script&lang=js&"
export * from "./BaseSelectOption.vue?vue&type=script&lang=js&"
import style0 from "./BaseSelectOption.vue?vue&type=style&index=0&id=1f498843&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f498843",
  null
  
)

export default component.exports