import BaseButton from '../components/base/BaseButton'
import BaseCard from '../components/base/BaseCard'
import BaseInput from '../components/base/BaseInput'
import BaseSelectOption from '../components/base/BaseSelectOption'
import Layout from "../components/general/Layout"
import Loading from '../components/custom/Loading'
import BaseTextArea from '../components/base/BaseTextArea'
import Modal from '../components/custom/Modal'

export default function(Vue) {
    Vue.component("BaseButton" , BaseButton)
    Vue.component("BaseCard" , BaseCard)
    Vue.component("BaseInput" , BaseInput)
    Vue.component("BaseSelectOption" , BaseSelectOption)
    Vue.component("Layout" , Layout)
    Vue.component("Loading" , Loading)
    Vue.component("BaseTextArea" , BaseTextArea)
    Vue.component("Modal" , Modal)
}
