<template>
    <button
        :style="{'background-color': backgroundColor, 'border-color': borderColor, 'color': color}"
        :class="classes"
        :disabled="disabled"
        @click="onClick"
    >
        {{ label }}
    </button>
</template>

<script>
export default {
    name: 'base-button',
    props: {
        label: {
            type: String,
            required: true,
        },
        backgroundColor: {
            type: String,
            default: '#1E88E5',
        },
        borderColor: {
            type: String,
            default: '#1E88E5',
        },
        color: {
            type: String,
            default: '#FFFFFF',
        },
        outline: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        shadow: {
            type: Boolean,
            default: false,
        },
        block: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        classes() {
            return {
                'button': true,
                'button-outline': this.outline,
                'button-shadow': this.shadow,
                'button-block': this.block,
            };
        },
    },
    methods: {
        onClick() {
            this.$emit('onClick');
        },
    },
}
</script>

<style scoped>
.button {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    border: 2px solid #1E88E5;
    border-radius: 50px;
    color: #FFFFFF;
    background-color: #1E88E5;
    height: 56px !important;
    min-height: 56px !important;
    padding: 0 46px !important;
    letter-spacing: 0;
    text-indent: 0;
    text-transform: none;
    width: fit-content;
    min-width: 212px;
}
.button:focus {
    box-shadow: none;
    outline: none
}
.button:disabled {
    border: 2px solid #D0D0D0 !important;
    background-color: #D0D0D0 !important;
    color: #686868 !important;
}
.button-outline {
    border: 2px solid #1E88E5 !important;
    background-color: #ffffff !important;
    color: #222222 !important;
}
.button.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: #D0D0D0 !important;
    color: #686868 !important;
}
.button.button-shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
}
.button.button-block {
    width: 100%;
}
</style>
