<template>
    <div :class="classes">
        <div class="inner-bg">
            <slot></slot>
        </div>
    </div>
</template>

<script>

export default {
    props: {},
    data() {
        return {}
    },
    computed: {
        classes() {
            return {
                'content': true,
            };
        },
    },
}
</script>

<style  lang="scss" scoped>
.content {
    min-height: calc(var(--vh, 1vh) * 100);
	position: relative;
    box-shadow: 0px 0px 10px grey;
    background-color: #FFFFFF;
    & .inner-bg{
        height: 100%;
        background-color: #ffffff;
    }
}

@media only screen and (max-width: 600px) {}
@media only screen and (min-width: 600px) {}
@media only screen and (min-width: 768px) {}
@media only screen and (min-width: 992px) {}
@media only screen and (min-width: 1200px) {
    .content{
        width: 100%;
        max-width: 480px;
        margin: 0 auto;
    }
}
</style>