import { service } from "./axios"

const request = async (instance, { cbSuccess, cbError }) => {
    try {
        const res = await instance
        if (res.status === 200) {
            cbSuccess(res)
        } else {
            cbError(res, "Error !")
        }
    } catch (e) {
        console.log(e)
        cbError(null, e)
    }
}

export const getProjectListAPI = ({ payload , cbSuccess , cbError }) => request(
    service.post(`/api/app/project/list`, payload) ,
    { cbSuccess , cbError }
)

export const getUserRoleAPI = ({ mid , cbSuccess , cbError }) => request(
    service.get(`/api/get-role/${mid}`) ,
    { cbSuccess , cbError }
)

export const uploadImageAPI = ({ payload , cbSuccess , cbError }) => request(
    service.post(`/api/upload`, payload) ,
    { cbSuccess , cbError }
)
