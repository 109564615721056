import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Index',
        component: () => import('../views/Index.vue')
    },
    {
        path: '/404',
        name: 'Not Found',
        component: () => import('../views/NotFound.vue')
    },
////// invitation //////
    {
        path: '/invite/driver',
        name: 'Invite Driver Link',
        component: () => import('../views/invitation/Driver.vue')
    },
    {
        path: '/invite/audit',
        name: 'Invite Audit Link',
        component: () => import('../views/invitation/Audit.vue')
    },
    {
        path: '/invite/supervisor',
        name: 'Invite Supervisor Link',
        component: () => import('../views/invitation/Supervisor.vue')
    },
    {
        path: '/invite/already',
        name: 'Invite Already',
        component: () => import('../views/invitation/Supervisor.vue')
    },
////// driver //////
    {
        path: '/driver/readiness',
        name: 'Driver Readiness List',
        component: () => import('../views/driver/readiness/List.vue')
    },
    {
        path: '/driver/readiness/history',
        name: 'Driver Readiness History',
        component: () => import('../views/driver/readiness/Detail.vue')
    },
    {
        path: '/driver/checklist',
        name: 'Driver Checklist',
        component: () => import('../views/driver/checklist/Index.vue')
    },
    {
        path: '/driver/checklist/truck',
        name: 'Driver Checklist Truck',
        component: () => import('../views/driver/checklist/Truck.vue')
    },
    {
        path: '/driver/checklist/layout',
        name: 'Driver Checklist Layout',
        component: () => import('../views/driver/checklist/Layout.vue')
    },
    {
        path: '/driver/checklist/list',
        name: 'Driver Checklist List',
        component: () => import('../views/driver/checklist/List.vue')
    },
    {
        path: '/driver/checklist/scan',
        name: 'Driver Checklist Scan',
        component: () => import('../views/driver/checklist/Scan.vue')
    },
    {
        path: '/driver/checklist/form',
        name: 'Driver Checklist Form',
        component: () => import('../views/driver/checklist/Form.vue')
    },
    {
        path: '/driver/checklist/success',
        name: 'Driver Checklist Success',
        component: () => import('../views/driver/checklist/Success.vue')
    },
////// Audit //////
    {
        path: '/audit',
        name: 'Audit Index',
        component: () => import('../views/audit/Index.vue')
    },
////// Audit Truck //////
    {
        path: '/audit/truck/readiness',
        name: 'Audit Truck Readiness List',
        component: () => import('../views/audit/truck/readiness/List.vue')
    },
    {
        path: '/audit/truck/readiness/history',
        name: 'Audit Truck Readiness History',
        component: () => import('../views/audit/truck/readiness/Detail.vue')
    },
    {
        path: '/audit/truck/checklist',
        name: 'Audit Truck Checklist',
        component: () => import('../views/audit/truck/checklist/Index.vue')
    },
    {
        path: '/audit/truck/checklist/truck',
        name: 'Audit Truck Checklist Truck',
        component: () => import('../views/audit/truck/checklist/Truck.vue')
    },
    {
        path: '/audit/truck/checklist/layout',
        name: 'Audit Truck Checklist Layout',
        component: () => import('../views/audit/truck/checklist/Layout.vue')
    },
    {
        path: '/audit/truck/checklist/list',
        name: 'Audit Truck Checklist List',
        component: () => import('../views/audit/truck/checklist/List.vue')
    },
    {
        path: '/audit/truck/checklist/form',
        name: 'Audit Truck Checklist Form',
        component: () => import('../views/audit/truck/checklist/Form.vue')
    },
    {
        path: '/audit/truck/checklist/success',
        name: 'Audit Truck Checklist Success',
        component: () => import('../views/audit/truck/checklist/Success.vue')
    },
////// Audit Driver //////
    {
        path: '/audit/driver/readiness',
        name: 'Audit Driver Readiness List',
        component: () => import('../views/audit/driver/readiness/List.vue')
    },
    {
        path: '/audit/driver/readiness/history',
        name: 'Audit Driver Readiness History',
        component: () => import('../views/audit/driver/readiness/Detail.vue')
    },
    {
        path: '/audit/driver/checklist',
        name: 'Audit Driver Checklist',
        component: () => import('../views/audit/driver/checklist/Index.vue')
    },
    {
        path: '/audit/driver/checklist/profile',
        name: 'Audit Driver Checklist Profile',
        component: () => import('../views/audit/driver/checklist/Profile.vue')
    },
    {
        path: '/audit/driver/checklist/list',
        name: 'Audit Driver Checklist List',
        component: () => import('../views/audit/driver/checklist/List.vue')
    },
    {
        path: '/audit/driver/checklist/form',
        name: 'Audit Driver Checklist Form',
        component: () => import('../views/audit/driver/checklist/Form.vue')
    },
    {
        path: '/audit/driver/checklist/success',
        name: 'Audit Driver Checklist Success',
        component: () => import('../views/audit/driver/checklist/Success.vue')
    },
////// Supervisor //////
    {
        path: '/supervisor',
        name: 'Supervisor Index',
        component: () => import('../views/supervisor/Index.vue')
    },
////// Supervisor Driver //////
    {
        path: '/supervisor/driver/readiness',
        name: 'Supervisor Driver Readiness List',
        component: () => import('../views/supervisor/driver/readiness/List.vue')
    },
    {
        path: '/supervisor/driver/checklist',
        name: 'Supervisor Driver Checklist',
        component: () => import('../views/supervisor/driver/checklist/List.vue')
    },
    {
        path: '/supervisor/driver/checklist/form',
        name: 'Supervisor Driver Checklist Form',
        component: () => import('../views/supervisor/driver/checklist/Form.vue')
    },
    {
        path: '/supervisor/driver/checklist/success',
        name: 'Supervisor Driver Checklist Success',
        component: () => import('../views/supervisor/driver/checklist/Success.vue')
    },
////// Supervisor Audit Truck //////
    {
        path: '/supervisor/audit/truck/readiness',
        name: 'Supervisor Audit Truck Readiness List',
        component: () => import('../views/supervisor/audit/truck/readiness/List.vue')
    },
    {
        path: '/supervisor/audit/truck/checklist',
        name: 'Supervisor Audit Truck Checklist List',
        component: () => import('../views/supervisor/audit/truck/checklist/List.vue')
    },
    {
        path: '/supervisor/audit/truck/checklist/form',
        name: 'Supervisor Audit Truck Checklist Form',
        component: () => import('../views/supervisor/audit/truck/checklist/Form.vue')
    },
////// Supervisor Audit Driver //////
    {
        path: '/supervisor/audit/driver/readiness',
        name: 'Supervisor Audit Driver Readiness List',
        component: () => import('../views/supervisor/audit/driver/readiness/List.vue')
    },
    {
        path: '/supervisor/audit/driver/checklist',
        name: 'Supervisor Audit Driver Checklist List',
        component: () => import('../views/supervisor/audit/driver/checklist/List.vue')
    },
    {
        path: '/supervisor/audit/driver/checklist/form',
        name: 'Supervisor Audit Driver Checklist Form',
        component: () => import('../views/supervisor/audit/driver/checklist/Form.vue')
    },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
