<template>
    <div :class="classes" @click="onClick">
        <div class="card-header">
            <slot name="header"></slot>
        </div>
        <div class="card-body">
            <div class="card-content">
                <slot name="body"></slot>
            </div>
            <div class="card-footer">
                <slot name="footer"></slot>
            </div>
        </div>
        <slot />
    </div>
</template>

<script>
export default {
    name: 'base-card',
    props: {
        cardFull: {
            type: Boolean,
            required: false,
        },
        layout: {
            type: String,
        },
    },
    computed: {
        classes() {
            return {
                'card-wrap': true,
                'card-full': this.cardFull,
                'layout-02': this.layout === '02',
                'layout-03': this.layout === '03',
            };
        },
    },
    methods: {
        onClick() {
            this.$emit('onClick');
        }
    },
}
</script>

<style scoped>
.card-wrap {
    /* padding: 24px 0; */
    /* background-color: #ffffff; */
    font-family: 'Sukhumvit set', Noto Sans, sans-serif;
    font-weight: 400;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: auto;
}
.card-header {
    position: relative;
}
.card-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color:#F4F4F4;
    /* max-height: calc(100vh - 148px); */
    /* overflow: auto; */
}
.card-body .card-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.card-wrap.layout-02 .card-header {
    box-shadow: 0px 4px 63px rgb(0 0 0 / 5%);
}
.card-wrap.layout-02 .card-body {
    /* max-height: calc(100vh - 104px); */
    background: linear-gradient(120.75deg, rgba(0, 0, 0, 0.05) 31.35%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.03), rgba(0, 0, 0, 0.03)), linear-gradient(286.57deg, #4EACFF 0%, #1E88E5 100%);
}
.card-wrap.layout-03 .card-body {
    /* max-height: 100vh; */
}
.card-full {
    min-height: calc(100vh);
}
</style>